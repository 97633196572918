nav {
    width: 100vw;
    height: 2.875rem;
    display: grid;
    place-items: center;
    background: var(--color-lilac);
    /* position: fixed; */
    top: 0;
    left: 0;
    z-index: 9;
    /* box-shadow: 0 1rem 1.5rem hsla(var(--primary-hue), 68%, 42%, 20%); */
    transition: var(--transition);
}

nav li {
    font-size: 1rem;
    font-weight: 700;
    line-height: 2.875rem;
    letter-spacing: .2em;
    text-align: center;
    text-transform: uppercase;
    padding: 0 2rem;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
}

nav li a:hover {
    color:var(--color-red);
}

nav.mcl-active {
            z-index:1000;
        }

.nav__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    /* gap: 3.5rem; */
}

.nav__menu a {
    color:var(--color-white);
}
/* HIDE NAV MENU ON TABLETS AND PHONES */
@media screen and (max-width: 1024px) {
    .nav__menu {
        display: none;
    }
}