* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    --color-black:#333;
    --color-blue:#3333ff; /*links*/
    --color-lightGray:#bbb;
    --color-lightGreen:#deeeee; /*contact form*/
    --color-lilac:#ccf;
    --color-lightLilac:#ecebff;
    --color-red:#d32539; /*hover links*/
    --color-white:#fff;
    --color-gray:#efefef;

    /* GENERAL CONTAINER VARIABLES */
    --container-width-lg: 76%;
    --container-width-md: 90%;

    /* BORDER RADIUS VARIABLES */
    --border-radius-1: 1.2rem;
    --border-radius-2: 0.8rem;
    --border-radius-3: 0.5rem;

    /* GENERAL TRANSITION VARIABLE */
    --transition: all 500ms ease;
}

html {
    scroll-behavior: smooth;
}

body {
    background: url('assets/white-square.jpg') repeat 0% 0%;
    font-family: 'Open Sans', sans-serif;
    font-size:1rem;
    line-height:1.75rem;
    color:var(--color-black);
    font-weight: 400;
    overflow-x:hidden;
}


/* GENERAL CONTAINER CLASS */
.container {
    width: var(--container-width-lg);
    margin-inline: auto;
    max-width: 1920px;
}

/* GENERAL SECTION STYLES */
section {
    padding: 10rem 0;
    background: var(--color-light);
    transition: var(--transition);
}

section h2 {
    color:var(--color-black);
    font-family: 'Aldrich', sans-serif;
    font-size:1.2rem;
    text-align: center;
    position:relative;
    text-transform: uppercase;
}

section h2:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 30px;
    background: var(--color-lilac);
    vertical-align: -40%;
    margin: 0 6px 0 0;
}

section > p {
    text-align: center;
    margin: 0.5rem auto 4rem;
    width: 40%;
}


/* GENERAL SECTION STYLES */
a {
    color: var(--color-red);
    transition: var(--transition);
}

/* GENERAL HEADING STYLES */
h1, h2, h3, h4, h5, h6 {
    line-height: 1.1;
    color: var(--color-black);
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.6rem;
}

h4 {
    font-size: 1.1rem;
}

h5 {
    font-size: 0.95rem;
}

h6 {
    font-size: 0.8rem;
}

/* GENERAL IMAGES STYLES */
img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* GENERAL BUTTON STYLES */
.btn {
    display: inline-block;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color:var(--color-white);
    color: var(--color-black);
    padding: 0.6rem 2.5rem;
    border: 2px solid transparent;
    border-radius: var(--border-radius-2);
    cursor: pointer;
}

.btn:hover {
    background-color: transparent;
    border-color: var(--color-lilac);
    transform: translateY(-0.5rem);
}

.btn.white {
    background: var(--color-white);
}

.btn.white:hover {
    background: transparent;
    border-color: var(--color-white);
}

.btn.primary {
    background: var(--color-lilac);
    color: white;
}

.btn.primary:hover {
    background:var(--color-light);
    background: linear-gradient(rgba(255,255,255,1), rgba(204, 204, 204, 1) ); 
    border: 1px solid var(--color-gray);
}

.btn.sm {
    padding: 0.5rem 1.2rem;
}




/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
    body {
        font-size: 1rem;
    }
    
    .container {
        width: var(--container-width-md);
    }

    section {
        padding: 8rem 0;
    }

    section > p {
        width: 72%;
        margin-bottom: 3rem;
    }

    h1 {
        font-size: 2rem;
    }
}





/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
    h1 {
        font-size: 2.4rem;
    }

    h2 {
        font-size: 1.8rem;
    }

    h3 {
        font-size: 1.4rem;
    }

    h4 {
        font-size: 1rem;
    }

    .btn {
        padding: 0.6rem 1.5rem;
    }

    section > p {
        width: var(--container-width-md);
    }
}
