footer {
    background: var(--color-lilac);
    padding-top: 2rem;
    box-shadow: 0 -1rem 1.5rem hsla(var(- --color-lightLilac), 68%, 42%, 20%);
    transition: var(--transition);
}

footer .nav__menu {
    justify-content: center;
}

footer h4 {
    font-weight:400;
}

.footer__socials {
    width: fit-content;
    display: flex;
    gap: 1.5rem;
    margin: 3rem auto 4rem;
}

.footer__socials a {
    background: var(--color-black);
    color: var(--color-white);
    padding: 0.3rem 0.6rem 0.1rem;
    border-radius: var(--border-radius-3);
}

.footer__socials a:hover {
    opacity: 0.7;
    transform: translateY(-0.5rem);
}

.footer__copyright {
    color: var(--color-black);
    text-align: center;
    /* border-top: 2px solid rgba(0, 0, 0, 0.2); */
    padding: 1.5rem 0;
}