.skills__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem 4rem;
}

.card.skill {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.skill__icon {
    background: var(--color-lilac);
    padding: 0.6rem 0.6rem 0.3rem;
    border-radius: var(--border-radius-3);
    font-size: 1.5rem;
    color: var(--color-white);
    transition: var(--transition);
}

.skill:hover .skill__icon {
    background: var(--color-light);
    color: var(--color-lilac);
}

.skill h4 {
    margin-bottom: 0.7rem;
}

#skills h4 {
    text-align: center;
    padding:1rem 0;
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
    .skills__container {
        gap: 1.5rem;
    }
}


/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
    .skills__container {
        grid-template-columns: 1fr;
        gap: 1.2rem;
    }

    .card.skill {
        gap: 1.2rem;
    }
}