header {
  background-color: var(--color-lightLilac);
  height: 10rem;
  display: grid;
  place-items: center;
  transition: var(--transition);
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.logo__container {
  flex: 1;
}

.logo {
  max-width: 300px;
  text-align: center;
  position: relative;
  visibility: hidden;
}
.logo a {
  color: var(--color-black);
  font-family: 'Aldrich', sans-serif;
  font-size: 2.5rem;
  line-height: 1rem;
  text-align: center;
}
.logo:before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 80px;
  background-color: var(--color-lilac);
  vertical-align: -150%;
  margin: 0 12px 0 0;
}

.logo-teaser {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  line-height: 1rem;
  display: block;
  text-align: center;
  margin-top: -25px;
}
.circles {
  flex: 1;
}
.circle {
  position: absolute;
  border-radius: 50%;
  opacity: 0.7;
  transition: width 2s, height 2s, transform 2s;
}

.c-1 {
  /* lilac */
  background: var(--color-lilac);
  position: absolute;
  width: 70px;
  height: 70px;
  top: 10px;
  right: 10%;
  border: 2px solid #dde89a;
  z-index: 80;
}

.c-2 {
  /* yellow */
  background: #fff579;
  position: absolute;
  width: 50px;
  height: 50px;
  top: -20px;
  right: 14%;
  border: 2px solid #fff579;
  z-index: 86;
}

.c-3 {
  /* blue */
  background: #8ed8f8;
  position: absolute;
  width: 60px;
  height: 60px;
  top: -30px;
  right: 10%;
  border: 2px solid #8ed8f8;
  z-index: 90;
}

.c-4 {
  /*red */
  background: #8a0651;
  width: 70px;
  height: 70px;
  top: -10px;
  right: 6%;
  border: 2px solid #8a0651;
  z-index: 95;
}

.c-5 {
  /*green */
  background: #dde89a;
  width: 70px;
  height: 70px;
  top: 20px;
  right: 8%;
  z-index: 100;
  text-align: center;
}
.circle.c-5 {
  opacity: 0.9;
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
  header {
    margin-top: 0;
    /* height: 100vh; */
  }
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
  .logo {
    max-width: 200px;
  }
  .logo a {
    font-size: 1.2rem;
    line-height: 1rem;
  }
  .logo:before {
    width: 8px;
    height: 40px;
    vertical-align: -80%;
    margin: 0 6px 0 0;
  }

  .logo-teaser {
    font-size: 0.6rem;
    line-height: 1rem;
    margin-top: -20px;
  }
}
