.contact__container {
    width: fit-content;
    display: flex;
    justify-content: center;
    gap: 3rem;
}

.contact__container a {
    width: 5rem;
    aspect-ratio: 1/1;
    color: white;
    background: var(--color-lilac);
    display: grid;
    place-items: center;
    font-size: 2rem;
    border: 2px solid transparent;
    border-radius: var(--border-radius-1);
}

.contact__container a:hover {
    background: transparent;
    border-color: var(--color-lilac);
    color: var(--color-lilac);
    transform: translateY(-0.5rem);
}

#contact h4 {
    text-align:center;
    padding: 1rem 0;
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
    .contact__container {
        gap: 1.5rem;
    }
}